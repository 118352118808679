<template>
    <v-app>
        <v-container>

            <v-snackbar absolute top color="primary" shaped v-model="snackbarLauch">
                {{ snack }}
            </v-snackbar>


            <v-row class="d-flex justify-center align-center">

                <v-card :width="900" class="opa elevation-12">
                    <v-card-title>
                        <v-icon left>mdi-email</v-icon>
                        Contactez-nous
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p>Pour toute demande de service, d'information ou pour une démonstration, n'hésitez
                                    pas
                                    à nous contacter :</p>
                                <div>
                                    <v-icon left>mdi-email</v-icon>
                                    <strong>Email :</strong> <a
                                        href="mailto:contact@cartovia.fr">contact@cartovia.fr</a>
                                </div>
                                <div>
                                    <v-icon left>mdi-phone</v-icon>
                                    <strong>Téléphone :</strong> <a href="tel:0698419863">0698419863</a>
                                </div>
                                <div>
                                    <v-icon left>mdi-map-marker</v-icon>
                                    <strong>Adresse :</strong>
                                    <a target="_blank" href="https://maps.app.goo.gl/FBafW88zDRbp28T87">
                                        21 Rue du Commandant Fuzier, Lyon 69003
                                    </a>
                                </div>
                                <div>
                                    <v-icon left>mdi-linkedin</v-icon>
                                    Linkedin: [Votre lien Linkedin]
                                </div>

                            </v-col>

                            <v-col>
                                <iframe width="100%" height="400" frameborder="0" style="border:0"
                                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDwgnLpxwnc65KKkOhMg_xA4vzz_3xx3cQ&q=21+Rue+du+Commandant+Fuzier,Lyon+69003"
                                    allowfullscreen>
                                </iframe>
                            </v-col>

                        </v-row>

                        <v-form v-if="!finished" ref="form" v-model="valid">
                            <h3>Remplissez ce formulaire pour toutes demandes d'informations</h3>

                            <v-text-field label="Prénom et nom" v-model="name" :rules="nameRules"
                                required></v-text-field>

                            <v-text-field label="Adresse e-mail" v-model="email" :rules="emailRules"
                                required></v-text-field>

                            <v-text-field label="Téléphone" v-model="phone" :rules="phoneRules" required></v-text-field>

                            <v-textarea label="Votre message" v-model="message" :rules="messageRules"
                                required></v-textarea>
                            <v-row>
                                <v-col class="text-right">
                                    <v-btn :disabled="!valid" color="primary" @click="submit">
                                        <v-icon left>mdi-send</v-icon>
                                        Envoyer ce formulaire
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>

            </v-row>
        </v-container>
    </v-app>
</template>


<script>
import xhr from "../plugins/axios";

export default {
    name: "Faq",
    data() {
        return {
            valid: false,
            finished: false,
            snackbarLauch: false,
            snack: '',
            name: '',
            email: '',
            phone: '',
            message: '',
            nameRules: [
                v => !!v || 'Le nom est obligatoire',
                v => (v && v.length >= 2) || 'Le nom doit contenir au moins 2 caractères'
            ],
            emailRules: [
                v => !!v || 'E-mail est obligatoire',
                v => /.+@.+\..+/.test(v) || 'E-mail doit être valide'
            ],
            phoneRules: [
                v => !!v || 'Le numéro de téléphone est obligatoire',
                v => /^((\+|00)33\s?|0)[67](\s?\d{2}){4}$/.test(v) || 'Doit être un numéro de téléphone français valide'
            ],
            messageRules: [
                v => !!v || 'Un message est obligatoire'
            ]
        }
    },
    methods: {
        async submit() {
            if (this.$refs.form.validate()) {

                try {
                    let name = this.name
                    let email = this.email
                    let phone = this.phone
                    let message = this.message
                    let res = await xhr.post('/contact', {
                        name,
                        email,
                        phone,
                        message
                    })
                    if (res.data) {

                        this.snackbarLauch = true
                        this.snack = 'Votre e-mail a bien été envoyé'
                        this.finished = true
                    }
                } catch (error) {
                    this.snackbarLaunch = true;
                    this.snack = 'Erreur lors de l\'envoi de l\'e-mail. Veuillez réessayer.';
                    console.error("Erreur lors de l'envoi du formulaire : ", error);

                }

            }
        }
    }
};
</script>